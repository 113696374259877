import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    async FETCH_CUSTOMER_ORDER_DATA() {
      try {
        const resp = await axios.get('/admin/show-all-customer-cart')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_CUSTOMER_ORDER_DATA_BY_ID(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-customer-cart/${data}`)
        if (resp.status === 200) {
          return resp
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
