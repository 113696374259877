import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // Parts
    async ADD_PART(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-home-appliance', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_PARTS() {
      try {
        const resp = await axios.get('/admin/show-all-home-appliance')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PART(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-home-appliance/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_PARTS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-home-appliance')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PART_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-home-appliance/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_PART(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-home-appliance/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_PART(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-home-appliance/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_PART(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-home-appliance/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
