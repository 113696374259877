export default [
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/calendar/index.vue'),
    meta: {
      pageTitle: 'Calendar',
      breadcrumb: [
        {
          text: 'Calendar',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/calendar/create-calendar-date',
    name: 'calendar-create-calendar-date',
    component: () => import('@/views/calendar/CreateCalendarDate.vue'),
    meta: {
      pageTitle: 'Create Calendar Date',
      breadcrumb: [
        {
          text: 'Calendar',
          active: false,
          to: '/calendar',
        },
        {
          text: 'Create Calendar Date',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/calendar/deleted-calendar-dates',
    name: 'calendar-deleted-calendar-dates',
    component: () => import('@/views/calendar/DeletedCalendarDates.vue'),
    meta: {
      pageTitle: 'Deleted Calendar Dates',
      breadcrumb: [
        {
          text: 'Calendar',
          active: false,
          to: '/calendar',
        },
        {
          text: 'Deleted Calendar Dates',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/calendar/update-calendar-date/:id',
    name: 'calendar-update-calendar-date',
    component: () => import('@/views/calendar/UpdateCalendarDate.vue'),
    meta: {
      pageTitle: 'Update Calendar Date',
      breadcrumb: [
        {
          text: 'Calendar',
          active: false,
          to: '/calendar',
        },
        {
          text: 'Update Calendar Date',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
