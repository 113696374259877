export default [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orders/OrdersList.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/create-order',
    name: 'create-order',
    component: () => import('@/views/orders/CreateOrder.vue'),
    meta: {
      pageTitle: 'Create New Order',
      breadcrumb: [
        {
          text: 'Create new order',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import('@/views/orders/OrderDetail.vue'),
    meta: {
      pageTitle: 'Order',
      breadcrumb: [
        {
          text: 'Orders',
          active: false,
          to: '/orders',
        },
        {
          text: 'Order',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
