import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // Part
    async ADD_TS_PART(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-technical-support-issue', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_TS_PARTS() {
      try {
        const resp = await axios.get('/admin/show-all-technical-support-issue')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_TS_PART(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-technical-support-issue/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_TS_DELETED_PARTS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-technical-support-issue')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_TS_PART_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-technical-support-issue/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_TS_PART(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-technical-support-issue/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_TS_PART(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-technical-support-issue/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_TS_PART(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-technical-support-issue/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Time Price
    async ADD_TS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-technical-support-time-price', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_TS_TIME_PRICE() {
      try {
        const resp = await axios.get('/admin/show-all-technical-support-time-price')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_TS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-technical-support-time-price/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_TS_DELETED_TIME_PRICE() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-technical-support-time-price')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_TS_TIME_PRICE_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-technical-support-time-price/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_TS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-technical-support-time-price/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_TS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-technical-support-time-price/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_TS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-technical-support-time-price/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
