import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    async ADD_POSTCODE(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-postal-code', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_POSTCODES() {
      try {
        const resp = await axios.get('/admin/show-all-postal-code')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_POSTCODE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-postal-code/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_POSTCODES() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-postal-code')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_POSTCODE(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-postal-code/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_POSTCODE(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-postal-code/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_POSTCODE_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-postal-code/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_POSTCODE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-postal-code/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    /** ******************************************
                  POSTCODE GROUP
     ******************************************* */
    async ADD_POSTCODE_GROUP(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-postal-code-group', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_POSTCODE_GROUPS() {
      try {
        const resp = await axios.get('/admin/show-all-postal-code-group')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_POSTCODE_GROUP(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-postal-code-group/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_POSTCODE_GROUP(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-postal-code-group/${data.id}`, data.form)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_POSTCODE_GROUPS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-postal-code-group')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_POSTCODE_GROUP(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-postal-code-group/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_POSTCODE_GROUP_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-postal-code-group/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_POSTCODE_GROUP(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-postal-code-group/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
