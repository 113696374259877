export default [
  {
    path: '/mobile-tablet',
    name: 'mobile-tablet',
    component: () => import('@/views/mobile-tablet/index.vue'),
    meta: {
      pageTitle: 'Mobile & Tablet',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Category
  {
    path: '/mobile-tablet/category',
    name: 'mobile-tablet-category',
    component: () => import('@/views/mobile-tablet/category/Category.vue'),
    meta: {
      pageTitle: 'Category',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Category',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/create-category',
    name: 'mobile-tablet-create-category',
    component: () => import('@/views/mobile-tablet/category/CreateCategory.vue'),
    meta: {
      pageTitle: 'Create Category',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Category',
          active: false,
          to: '/mobile-tablet/category',
        },
        {
          text: 'Create Category',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/update-category/:id',
    name: 'mobile-tablet-update-category',
    component: () => import('@/views/mobile-tablet/category/UpdateCategory.vue'),
    meta: {
      pageTitle: 'Update Category',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Category',
          active: false,
          to: '/mobile-tablet/category',
        },
        {
          text: 'Update Category',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/deleted-category',
    name: 'mobile-tablet-deleted-category',
    component: () => import('@/views/mobile-tablet/category/DeletedCategory.vue'),
    meta: {
      pageTitle: 'Deleted Category',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Category',
          active: false,
          to: '/mobile-tablet/category',
        },
        {
          text: 'Deleted Category',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Brands
  {
    path: '/mobile-tablet/brands',
    name: 'mobile-tablet-brands',
    component: () => import('@/views/mobile-tablet/brands/Brands.vue'),
    meta: {
      pageTitle: 'Brands',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Brands',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/create-brand',
    name: 'mobile-tablet-create-brand',
    component: () => import('@/views/mobile-tablet/brands/CreateBrand.vue'),
    meta: {
      pageTitle: 'Mobile & Tablet',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Brands',
          active: false,
          to: '/mobile-tablet/brands',
        },
        {
          text: 'Create Brands',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/update-brand/:id',
    name: 'mobile-tablet-update-brand',
    component: () => import('@/views/mobile-tablet/brands/UpdateBrand.vue'),
    meta: {
      pageTitle: 'Update Brand',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Brands',
          active: false,
          to: '/mobile-tablet/brands',
        },
        {
          text: 'Update Brand',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/deleted-brands',
    name: 'mobile-tablet-deleted-brands',
    component: () => import('@/views/mobile-tablet/brands/DeletedBrands.vue'),
    meta: {
      pageTitle: 'Deleted Brands',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Brands',
          active: false,
          to: '/mobile-tablet/brands',
        },
        {
          text: 'Deleted Brands',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Models
  {
    path: '/mobile-tablet/models',
    name: 'mobile-tablet-models',
    component: () => import('@/views/mobile-tablet/models/Models.vue'),
    meta: {
      pageTitle: 'Models',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Models',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/create-model',
    name: 'mobile-tablet-create-model',
    component: () => import('@/views/mobile-tablet/models/CreateModel.vue'),
    meta: {
      pageTitle: 'Mobile & Tablet',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Models',
          active: false,
          to: '/mobile-tablet/models',
        },
        {
          text: 'Create Model',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/update-model/:id',
    name: 'mobile-tablet-update-model',
    component: () => import('@/views/mobile-tablet/models/UpdateModel.vue'),
    meta: {
      pageTitle: 'Update Model',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Models',
          active: false,
          to: '/mobile-tablet/models',
        },
        {
          text: 'Update Model',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/deleted-models',
    name: 'mobile-tablet-deleted-models',
    component: () => import('@/views/mobile-tablet/models/DeletedModels.vue'),
    meta: {
      pageTitle: 'Deleted Models',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Models',
          active: false,
          to: '/mobile-tablet/models',
        },
        {
          text: 'Deleted Models',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Parts
  {
    path: '/mobile-tablet/parts',
    name: 'mobile-tablet-parts',
    component: () => import('@/views/mobile-tablet/parts/Parts.vue'),
    meta: {
      pageTitle: 'Parts',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Parts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/create-part',
    name: 'mobile-tablet-create-part',
    component: () => import('@/views/mobile-tablet/parts/CreatePart.vue'),
    meta: {
      pageTitle: 'Mobile & Tablet',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Parts',
          active: false,
          to: '/mobile-tablet/parts',
        },
        {
          text: 'Create Part',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/deleted-parts',
    name: 'mobile-tablet-deleted-parts',
    component: () => import('@/views/mobile-tablet/parts/DeletedParts.vue'),
    meta: {
      pageTitle: 'Deleted Parts',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Parts',
          active: false,
          to: '/mobile-tablet/parts',
        },
        {
          text: 'Deleted Parts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/update-part/:id',
    name: 'mobile-tablet-update-part',
    component: () => import('@/views/mobile-tablet/parts/UpdatePart.vue'),
    meta: {
      pageTitle: 'Update Part',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Parts',
          active: false,
          to: '/mobile-tablet/parts',
        },
        {
          text: 'Update Part',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Colors
  {
    path: '/mobile-tablet/colors',
    name: 'mobile-tablet-colors',
    component: () => import('@/views/mobile-tablet/colors/Colors.vue'),
    meta: {
      pageTitle: 'Colors',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Colors',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/create-color',
    name: 'mobile-tablet-create-color',
    component: () => import('@/views/mobile-tablet/colors/CreateColor.vue'),
    meta: {
      pageTitle: 'Mobile & Tablet',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Colors',
          active: false,
          to: '/mobile-tablet/colors',
        },
        {
          text: 'Create Color',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/deleted-colors',
    name: 'mobile-tablet-deleted-colors',
    component: () => import('@/views/mobile-tablet/colors/DeletedColors.vue'),
    meta: {
      pageTitle: 'Deleted Colors',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Colors',
          active: false,
          to: '/mobile-tablet/colors',
        },
        {
          text: 'Deleted Colors',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mobile-tablet/update-color/:id',
    name: 'mobile-tablet-update-color',
    component: () => import('@/views/mobile-tablet/colors/UpdateColor.vue'),
    meta: {
      pageTitle: 'Update Color',
      breadcrumb: [
        {
          text: 'Mobile & Tablet',
          active: false,
          to: '/mobile-tablet',
        },
        {
          text: 'Colors',
          active: false,
          to: '/mobile-tablet/colors',
        },
        {
          text: 'Update Color',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
