import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import postcode from './postcode'
import user from './user'
import category from './category'
import macLaptopPc from './mac-laptop-pc'
import smartHome from './smart-home'
import technicalSupport from './technicalSupport'
import businessITSupport from './business-it-support'
import mobileTablet from './mobile-tablet'
import calendar from './calendar'
import discounts from './discounts'
import orders from './orders'
import reports from './reports/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    postcode,
    user,
    category,
    macLaptopPc,
    smartHome,
    technicalSupport,
    businessITSupport,
    mobileTablet,
    calendar,
    discounts,
    orders,
    reports,
  },
  strict: process.env.DEV,
})
