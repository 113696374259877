export default [
  {
    path: '/smart-home/parts',
    name: 'smart-home-parts',
    component: () => import('@/views/smart-home/parts/Parts.vue'),
    meta: {
      pageTitle: 'Parts',
      breadcrumb: [
        {
          text: 'Parts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/smart-home/create-part',
    name: 'smart-home-create-part',
    component: () => import('@/views/smart-home/parts/CreatePart.vue'),
    meta: {
      pageTitle: 'Smart Home',
      breadcrumb: [
        {
          text: 'Parts',
          active: false,
          to: '/smart-home/parts',
        },
        {
          text: 'Create Part',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/smart-home/deleted-parts',
    name: 'smart-home-deleted-parts',
    component: () => import('@/views/smart-home/parts/DeletedParts.vue'),
    meta: {
      pageTitle: 'Deleted Brands',
      breadcrumb: [
        {
          text: 'Parts',
          active: false,
          to: '/smart-home/parts',
        },
        {
          text: 'Deleted Parts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/smart-home/update-part/:id',
    name: 'smart-home-update-part',
    component: () => import('@/views/smart-home/parts/UpdatePart.vue'),
    meta: {
      pageTitle: 'Update Type',
      breadcrumb: [
        {
          text: 'Parts',
          active: false,
          to: '/smart-home/parts',
        },
        {
          text: 'Update Part',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
