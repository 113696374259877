export default [
  {
    path: '/discounts',
    name: 'discounts',
    component: () => import('@/views/discounts/index.vue'),
    meta: {
      pageTitle: 'Discounts',
      breadcrumb: [
        {
          text: 'Discounts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Promocode
  {
    path: '/discounts/promocodes',
    name: 'discounts-promocodes',
    component: () => import('@/views/discounts/promocode/Promocodes.vue'),
    meta: {
      pageTitle: 'Promocodes',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Promocodes',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/create-promocode',
    name: 'discounts-create-promocode',
    component: () => import('@/views/discounts/promocode/CreatePromocode.vue'),
    meta: {
      pageTitle: 'Create Promocode',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Promocodes',
          active: false,
          to: '/discounts/promocodes',
        },
        {
          text: 'Create Promocode',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/deleted-promocodes',
    name: 'discounts-deleted-promocodes',
    component: () => import('@/views/discounts/promocode/DeletedPromocodes.vue'),
    meta: {
      pageTitle: 'Deleted Promocodes',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Promocodes',
          active: false,
          to: '/discounts/promocodes',
        },
        {
          text: 'Deleted Promocodes',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/update-promocode/:id',
    name: 'discounts-update-promocode',
    component: () => import('@/views/discounts/promocode/UpdatePromocode.vue'),
    meta: {
      pageTitle: 'Update Promocode',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Promocode',
          active: false,
          to: '/discounts/promocodes',
        },
        {
          text: 'Update Promocode',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Multi Device
  {
    path: '/discounts/multidevices',
    name: 'discounts-multidevices',
    component: () => import('@/views/discounts/multidevice/MultiDevice.vue'),
    meta: {
      pageTitle: 'Multi Device',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'MultiDevice',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/create-multidevice',
    name: 'discounts-create-multidevice',
    component: () => import('@/views/discounts/multidevice/CreateMultiDevice.vue'),
    meta: {
      pageTitle: 'Create Multi Device Discount',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Multi Device Discounts',
          active: false,
          to: '/discounts/multidevices',
        },
        {
          text: 'Create Mutli Device Discount',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/deleted-multidevices',
    name: 'discounts-deleted-multidevices',
    component: () => import('@/views/discounts/multidevice/DeletedMultiDevices.vue'),
    meta: {
      pageTitle: 'Deleted Mutli Device Discounts',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Multi Device Discounts',
          active: false,
          to: '/discounts/multidevices',
        },
        {
          text: 'Deleted Mutli Device Discounts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/update-multidevice/:id',
    name: 'discounts-update-multidevice',
    component: () => import('@/views/discounts/multidevice/UpdateMultiDevice.vue'),
    meta: {
      pageTitle: 'Update Multi Device Discount',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Multi Device Discounts',
          active: false,
          to: '/discounts/multidevices',
        },
        {
          text: 'Update Multi Device Discount',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Multi Item
  {
    path: '/discounts/multiitems',
    name: 'discounts-multiitems',
    component: () => import('@/views/discounts/multiitem/MultiItem.vue'),
    meta: {
      pageTitle: 'Multi Item',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'MultiItem',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/create-multiitem',
    name: 'discounts-create-multiitem',
    component: () => import('@/views/discounts/multiitem/CreateMultiItem.vue'),
    meta: {
      pageTitle: 'Create Multi Item Discount',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Multi Item Discounts',
          active: false,
          to: '/discounts/multiitems',
        },
        {
          text: 'Create Mutli Item Discount',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/deleted-multiitems',
    name: 'discounts-deleted-multiitems',
    component: () => import('@/views/discounts/multiitem/DeletedMultiItems.vue'),
    meta: {
      pageTitle: 'Deleted Mutli Item Discounts',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Multi Item Discounts',
          active: false,
          to: '/discounts/multiitems',
        },
        {
          text: 'Deleted Mutli Item Discounts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/update-multiitem/:id',
    name: 'discounts-update-multiitem',
    component: () => import('@/views/discounts/multiitem/UpdateMultiItem.vue'),
    meta: {
      pageTitle: 'Update Multi Item Discount',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Multi Item Discounts',
          active: false,
          to: '/discounts/multiitems',
        },
        {
          text: 'Update Multi Item Discount',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Price Factor
  {
    path: '/discounts/pricefactors',
    name: 'discounts-pricefactors',
    component: () => import('@/views/discounts/pricefactor/PriceFactor.vue'),
    meta: {
      pageTitle: 'Price Factor',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Price Factor',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/create-pricefactor',
    name: 'discounts-create-pricefactor',
    component: () => import('@/views/discounts/pricefactor/CreatePriceFactor.vue'),
    meta: {
      pageTitle: 'Create Price Factor',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Price Factor',
          active: false,
          to: '/discounts/pricefactors',
        },
        {
          text: 'Create Price Factor',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/deleted-pricefactors',
    name: 'discounts-deleted-pricefactors',
    component: () => import('@/views/discounts/pricefactor/DeletedPriceFactors.vue'),
    meta: {
      pageTitle: 'Deleted Price Factors',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Price Factor',
          active: false,
          to: '/discounts/pricefactors',
        },
        {
          text: 'Deleted Price Factors',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discounts/update-pricefactor/:id',
    name: 'discounts-update-pricefactor',
    component: () => import('@/views/discounts/pricefactor/UpdatePriceFactor.vue'),
    meta: {
      pageTitle: 'Update Price Factor Discount',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: '/discounts',
        },
        {
          text: 'Price Factors',
          active: false,
          to: '/discounts/pricefactors',
        },
        {
          text: 'Update Price Factor',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
