export default [
  {
    path: '/customer-order',
    name: 'customer-order',
    component: () => import('@/views/reports/customer-order/View.vue'),
    meta: {
      pageTitle: 'Customer Order',
      breadcrumb: [
        {
          text: 'Customer Order',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/customer-order/:id',
    name: 'customer-order-detail',
    component: () => import('@/views/reports/customer-order/Detail.vue'),
    meta: {
      pageTitle: 'Customer Order Details',
      breadcrumb: [
        {
          text: 'Customer Order',
          active: false,
          to: '/customer-order',
        },
        {
          text: 'Customer Order Details',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
