export default [
  {
    path: '/mac-laptop-pc',
    name: 'mac-laptop-pc',
    component: () => import('@/views/mac-laptop-pc/index.vue'),
    meta: {
      pageTitle: 'Mac, Laptop & PC',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Brands
  {
    path: '/mac-laptop-pc/brands',
    name: 'mac-laptop-pc-brands',
    component: () => import('@/views/mac-laptop-pc/brands/Brands.vue'),
    meta: {
      pageTitle: 'Brands',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Brands',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mac-laptop-pc/create-brand',
    name: 'mac-laptop-pc-create-brand',
    component: () => import('@/views/mac-laptop-pc/brands/CreateBrand.vue'),
    meta: {
      pageTitle: 'Mac, Laptop & PC',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Brands',
          active: false,
          to: '/mac-laptop-pc/brands',
        },
        {
          text: 'Create Brands',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mac-laptop-pc/update-brand/:id',
    name: 'mac-laptop-pc-update-brand',
    component: () => import('@/views/mac-laptop-pc/brands/UpdateBrand.vue'),
    meta: {
      pageTitle: 'Update Brand',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Brands',
          active: false,
          to: '/mac-laptop-pc/brands',
        },
        {
          text: 'Update Brand',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mac-laptop-pc/deleted-brands',
    name: 'mac-laptop-pc-deleted-brands',
    component: () => import('@/views/mac-laptop-pc/brands/DeletedBrands.vue'),
    meta: {
      pageTitle: 'Deleted Brands',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Brands',
          active: false,
          to: '/mac-laptop-pc/brands',
        },
        {
          text: 'Deleted Brands',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Types
  {
    path: '/mac-laptop-pc/types',
    name: 'mac-laptop-pc-types',
    component: () => import('@/views/mac-laptop-pc/types/Types.vue'),
    meta: {
      pageTitle: 'Types',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Types',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mac-laptop-pc/create-type',
    name: 'mac-laptop-pc-create-type',
    component: () => import('@/views/mac-laptop-pc/types/CreateType.vue'),
    meta: {
      pageTitle: 'Mac, Laptop & PC',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Types',
          active: false,
          to: '/mac-laptop-pc/types',
        },
        {
          text: 'Create Type',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mac-laptop-pc/deleted-types',
    name: 'mac-laptop-pc-deleted-types',
    component: () => import('@/views/mac-laptop-pc/types/DeletedTypes.vue'),
    meta: {
      pageTitle: 'Deleted Brands',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Types',
          active: false,
          to: '/mac-laptop-pc/types',
        },
        {
          text: 'Deleted Types',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mac-laptop-pc/update-type/:id',
    name: 'mac-laptop-pc-update-type',
    component: () => import('@/views/mac-laptop-pc/types/UpdateType.vue'),
    meta: {
      pageTitle: 'Update Type',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Types',
          active: false,
          to: '/mac-laptop-pc/types',
        },
        {
          text: 'Update Type',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Parts
  {
    path: '/mac-laptop-pc/parts',
    name: 'mac-laptop-pc-parts',
    component: () => import('@/views/mac-laptop-pc/parts/Parts.vue'),
    meta: {
      pageTitle: 'Parts',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Parts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mac-laptop-pc/create-part',
    name: 'mac-laptop-pc-create-part',
    component: () => import('@/views/mac-laptop-pc/parts/CreatePart.vue'),
    meta: {
      pageTitle: 'Mac, Laptop & PC',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Parts',
          active: false,
          to: '/mac-laptop-pc/parts',
        },
        {
          text: 'Create Part',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mac-laptop-pc/deleted-parts',
    name: 'mac-laptop-pc-deleted-parts',
    component: () => import('@/views/mac-laptop-pc/parts/DeletedParts.vue'),
    meta: {
      pageTitle: 'Deleted Parts',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Parts',
          active: false,
          to: '/mac-laptop-pc/parts',
        },
        {
          text: 'Deleted Parts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/mac-laptop-pc/update-part/:id',
    name: 'mac-laptop-pc-update-part',
    component: () => import('@/views/mac-laptop-pc/parts/UpdatePart.vue'),
    meta: {
      pageTitle: 'Update Part',
      breadcrumb: [
        {
          text: 'Mac, Laptop & PC',
          active: false,
          to: '/mac-laptop-pc',
        },
        {
          text: 'Parts',
          active: false,
          to: '/mac-laptop-pc/parts',
        },
        {
          text: 'Update Part',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
