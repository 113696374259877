import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    USER: null,
  },
  getters: {
  },
  mutations: {
    SET_USER(state, payload) {
      state.USER = payload
    },
  },
  actions: {
    // Admin
    async ADD_USER(ctx, data) {
      try {
        const resp = await axios.post('/admin/register', data)
        if (resp.status === 200) {
          return resp
        }
        return null
      } catch (err) {
        return null
      }
    },
    async ASSIGN_POSTAL_GROUP_TO_USER(ctx, data) {
      try {
        const resp = await axios.post('/admin/assign-postal-group', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_USERS() {
      try {
        const resp = await axios.get('/admin/show-all-users')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_USER(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-single-user/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_USER(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-user/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_USER(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-user/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_USERS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-user')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_USER_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-user/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_USER(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-user/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // AUTH
    async VALIDATE_LOGIN({ commit }, data) {
      try {
        const resp = await axios.post('/auth/login', data)
        if (resp.status === 200) {
          const { user, token } = resp.data.data
          localStorage.setItem('token', token)
          commit('SET_USER', user)
          return true
        }
        return false
      } catch (err) {
        return null
      }
    },
    async LOGOUT({ commit }) {
      try {
        const resp = await axios.post('/auth/logout')
        if (resp.status === 200) {
          localStorage.removeItem('token')
          commit('SET_USER', null)
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_AUTH_USER({ commit }) {
      try {
        const resp = await axios.get('/auth/show-user')
        if (resp.status === 200) {
          commit('SET_USER', resp.data.data.user[0])
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async ADD_AVATAR({ commit }, data) {
      try {
        const resp = await axios.post('/auth/upload-user-avatar', data)
        if (resp.status === 200) {
          commit('SET_USER', resp.data.data[0])
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async REMOVE_AVATAR({ commit }) {
      try {
        const resp = await axios.get('/auth/remove-user-avatar')
        if (resp.status === 200) {
          commit('SET_USER', resp.data.data[0])
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_PASSWORD({ commit }, data) {
      try {
        const resp = await axios.post('/auth/update-password', data)
        if (resp.status === 200) {
          const { user, token } = resp.data.data
          localStorage.setItem('token', token)
          commit('SET_USER', user[0])
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_PROFILE(ctx, data) {
      try {
        const resp = await axios.post('/auth/update-profile', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Not used yet. Disbale account
    async DELETE_AUTH_USER(ctx, data) {
      try {
        const resp = await axios.delete(`/auth/delete/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },

  },
}
