import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // Service
    async ADD_BS_SERVICE(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-business-support-service', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_BS_SERVICE() {
      try {
        const resp = await axios.get('/admin/show-all-business-support-service')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BS_SERVICE(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-business-support-service/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_BS_DELETED_SERVICE() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-business-support-service')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BS_SERVICE_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-business-support-service/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_BS_SERVICE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-business-support-service/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_BS_SERVICE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-business-support-service/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_BS_SERVICE(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-business-support-service/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Issue
    async ADD_BS_ISSUE(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-business-support-issue', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_BS_ISSUE() {
      try {
        const resp = await axios.get('/admin/show-all-business-support-issue')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BS_ISSUE(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-business-support-issue/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_BS_DELETED_ISSUE() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-business-support-issue')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BS_ISSUE_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-business-support-issue/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_BS_ISSUE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-business-support-issue/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_BS_ISSUE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-business-support-issue/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_BS_ISSUE(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-business-support-issue/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Time Price
    async ADD_BS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-business-support-time-price', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_BS_TIME_PRICE() {
      try {
        const resp = await axios.get('/admin/show-all-business-support-time-price')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-business-support-time-price/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_BS_DELETED_TIME_PRICE() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-business-support-time-price')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BS_TIME_PRICE_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-business-support-time-price/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_BS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-business-support-time-price/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_BS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-business-support-time-price/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_BS_TIME_PRICE(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-business-support-time-price/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
