export default [
  {
    path: '/business-it-support',
    name: 'business-it-support',
    component: () => import('@/views/business-it-support/index.vue'),
    meta: {
      pageTitle: 'Business IT Support',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Services
  {
    path: '/business-it-support/services',
    name: 'business-it-support-services',
    component: () => import('@/views/business-it-support/services/Services.vue'),
    meta: {
      pageTitle: 'Services',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Services',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/business-it-support/create-service',
    name: 'business-it-support-create-service',
    component: () => import('@/views/business-it-support/services/CreateService.vue'),
    meta: {
      pageTitle: 'Create Service',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Services',
          active: false,
          to: '/business-it-support/services',
        },
        {
          text: 'Create Service',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/business-it-support/deleted-services',
    name: 'business-it-support-deleted-services',
    component: () => import('@/views/business-it-support/services/DeletedServices.vue'),
    meta: {
      pageTitle: 'Deleted Services',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Services',
          active: false,
          to: '/business-it-support/services',
        },
        {
          text: 'Deleted Services',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/business-it-support/update-service/:id',
    name: 'business-it-support-update-service',
    component: () => import('@/views/business-it-support/services/UpdateService.vue'),
    meta: {
      pageTitle: 'Update Service',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Service',
          active: false,
          to: '/business-it-support/services',
        },
        {
          text: 'Update Service',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Issues
  {
    path: '/business-it-support/issues',
    name: 'business-it-support-issues',
    component: () => import('@/views/business-it-support/issues/Issues.vue'),
    meta: {
      pageTitle: 'Issues',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Issues',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/business-it-support/create-issue',
    name: 'business-it-support-create-issue',
    component: () => import('@/views/business-it-support/issues/CreateIssue.vue'),
    meta: {
      pageTitle: 'Create Issue',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Issues',
          active: false,
          to: '/business-it-support/issues',
        },
        {
          text: 'Create Issue',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/business-it-support/deleted-issues',
    name: 'business-it-support-deleted-issues',
    component: () => import('@/views/business-it-support/issues/DeletedIssues.vue'),
    meta: {
      pageTitle: 'Deleted Issues',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Issues',
          active: false,
          to: '/business-it-support/issues',
        },
        {
          text: 'Deleted Issues',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/business-it-support/update-issue/:id',
    name: 'business-it-support-update-issue',
    component: () => import('@/views/business-it-support/issues/UpdateIssue.vue'),
    meta: {
      pageTitle: 'Update Issue',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Issues',
          active: false,
          to: '/business-it-support/issues',
        },
        {
          text: 'Update Issue',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Time & Price
  {
    path: '/business-it-support/time-price',
    name: 'business-it-support-time-price',
    component: () => import('@/views/business-it-support/time-price/TimePrice.vue'),
    meta: {
      pageTitle: 'Time and Price',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Time and Price',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/business-it-support/create-time-price',
    name: 'business-it-support-create-time-price',
    component: () => import('@/views/business-it-support/time-price/CreateTimePrice.vue'),
    meta: {
      pageTitle: 'Create Time and Price',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Time Price',
          active: false,
          to: '/business-it-support/time-price',
        },
        {
          text: 'Create Time and Price',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/business-it-support/deleted-time-price',
    name: 'business-it-support-deleted-time-price',
    component: () => import('@/views/business-it-support/time-price/DeletedTimePrice.vue'),
    meta: {
      pageTitle: 'Deleted Time and Price',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Time Price',
          active: false,
          to: '/business-it-support/time-price',
        },
        {
          text: 'Deleted Time and Price',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/business-it-support/update-time-price/:id',
    name: 'business-it-support-update-time-price',
    component: () => import('@/views/business-it-support/time-price/UpdateTimePrice.vue'),
    meta: {
      pageTitle: 'Update Time Price',
      breadcrumb: [
        {
          text: 'Business IT Support',
          active: false,
          to: '/business-it-support',
        },
        {
          text: 'Time and Price',
          active: false,
          to: '/business-it-support/time-price',
        },
        {
          text: 'Update Time and Price',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
