import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_ROOT_API,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = token
    }
    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  res => res,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('token')
      window.location = '/login'
      // Vue.$router.push('/login')
    }
    const { data } = error.response.data
    if (Object.entries(data).length > 0 && data.constructor === Object) {
      Object.keys(data).forEach(key => {
        data[key].forEach(elem => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: key,
              text: elem,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          }, {
            position: 'bottom-right',
          })
        })
      })
    } else {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          text: error.response.data.message,
          variant: 'danger',
        },
      }, {
        position: 'bottom-right',
      })
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$axios = axiosIns

export default axiosIns
