import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, IconsPlugin,
} from 'bootstrap-vue'
// import VueCompositionAPI from '@vue/composition-api'

import en from 'vee-validate/dist/locale/en.json'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import Multiselect from 'vue-multiselect'
// Vue Filepond
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

import './libs/vue-select'
import 'vue-select/dist/vue-select.css'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

Vue.component('multiselect', Multiselect)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
localize('en', en)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// Composition API
// Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
)

Vue.component('FilePond', FilePond)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
