import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // Promocode
    async SHOW_ALL_PROMOCODE() {
      try {
        const resp = await axios.get('/admin/show-all-promocode')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async ADD_PROMOCODE(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-promocode', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PROMOCODE(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-promocode/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_PROMOCODE() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-promocode')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PROMOCODE_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-promocode/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_PROMOCODE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-promocode/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_PROMOCODE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-promocode/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_PROMOCODE(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-promocode/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Multi Device
    async SHOW_ALL_MULTI_DEVICE() {
      try {
        const resp = await axios.get('/admin/show-all-multi-device-discount')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async ADD_MULTI_DEVICE(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-multi-device-discount', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_MULTI_DEVICE(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-multi-device-discount/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_MULTI_DEVICE() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-multi-device-discount')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_MULTI_DEVICE_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-multi-device-discount/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_MULTI_DEVICE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-multi-device-discount/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_MULTI_DEVICE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-multi-device-discount/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_MULTI_DEVICE(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-multi-device-discount/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Multi Item
    async SHOW_ALL_MULTI_ITEM() {
      try {
        const resp = await axios.get('/admin/show-all-multi-item-discount')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async ADD_MULTI_ITEM(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-multi-item-discount', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_MULTI_ITEM(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-multi-item-discount/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_MULTI_ITEM() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-multi-item-discount')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_MULTI_ITEM_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-multi-item-discount/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_MULTI_ITEM(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-multi-item-discount/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_MULTI_ITEM(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-multi-item-discount/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_MULTI_ITEM(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-multi-item-discount/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Price Factor
    async SHOW_ALL_PRICE_FACTOR() {
      try {
        const resp = await axios.get('/admin/show-all-price-factor')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async ADD_PRICE_FACTOR(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-price-factor', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PRICE_FACTOR(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-price-factor/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_PRICE_FACTOR() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-price-factor')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PRICE_FACTOR_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-price-factor/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_PRICE_FACTOR(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-price-factor/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_PRICE_FACTOR(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-price-factor/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_PRICE_FACTOR(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-price-factor/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
