import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    async FETCH_ALL_ORDERS() {
      try {
        const resp = await axios.get('/admin/show-all-orders')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ORDER_BY_ID(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-order/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_ORDER_BY_ID(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-order/${data.id}`, data.data)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async CREATE_ORDER(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-order', data)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
