import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // Calendar
    async SHOW_ALL_CALENDAR_DATE() {
      try {
        const resp = await axios.get('/admin/show-all-calender')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async ADD_CALENDAR_DATE(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-calender', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_CALENDAR_DATE(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-calender/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_CALENDAR_DATE() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-calender')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_CALENDAR_DATE_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-calender/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_CALENDAR_DATE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-calender/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_CALENDAR_DATE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-calender/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_CALENDAR_DATE(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-calender/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
