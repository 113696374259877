export default [
  {
    path: '/technical-support',
    name: 'technical-support',
    component: () => import('@/views/technical-support/index.vue'),
    meta: {
      pageTitle: 'Technical Support',
      breadcrumb: [
        {
          text: 'Technical Support',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Parts
  {
    path: '/technical-support/parts',
    name: 'technical-support-parts',
    component: () => import('@/views/technical-support/parts/Parts.vue'),
    meta: {
      pageTitle: 'Parts',
      breadcrumb: [
        {
          text: 'Technical Support',
          active: false,
          to: '/technical-support',
        },
        {
          text: 'Parts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/technical-support/create-part',
    name: 'technical-support-create-part',
    component: () => import('@/views/technical-support/parts/CreatePart.vue'),
    meta: {
      pageTitle: 'Technical Support',
      breadcrumb: [
        {
          text: 'Technical Support',
          active: false,
          to: '/technical-support',
        },
        {
          text: 'Parts',
          active: false,
          to: '/technical-support/parts',
        },
        {
          text: 'Create Part',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/technical-support/deleted-parts',
    name: 'technical-support-deleted-parts',
    component: () => import('@/views/technical-support/parts/DeletedParts.vue'),
    meta: {
      pageTitle: 'Deleted Parts',
      breadcrumb: [
        {
          text: 'Technical Support',
          active: false,
          to: '/technical-support',
        },
        {
          text: 'Parts',
          active: false,
          to: '/technical-support/parts',
        },
        {
          text: 'Deleted Parts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/technical-support/update-part/:id',
    name: 'technical-support-update-part',
    component: () => import('@/views/technical-support/parts/UpdatePart.vue'),
    meta: {
      pageTitle: 'Update Part',
      breadcrumb: [
        {
          text: 'Technical Support',
          active: false,
          to: '/technical-support',
        },
        {
          text: 'Parts',
          active: false,
          to: '/technical-support/parts',
        },
        {
          text: 'Update Part',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  // Time & Price
  {
    path: '/technical-support/time-price',
    name: 'technical-support-time-price',
    component: () => import('@/views/technical-support/time-price/TimePrice.vue'),
    meta: {
      pageTitle: 'Time and Price',
      breadcrumb: [
        {
          text: 'Technical Support',
          active: false,
          to: '/technical-support',
        },
        {
          text: 'Time and Price',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/technical-support/create-time-price',
    name: 'technical-support-create-time-price',
    component: () => import('@/views/technical-support/time-price/CreateTimePrice.vue'),
    meta: {
      pageTitle: 'Create Time and Price',
      breadcrumb: [
        {
          text: 'Technical Support',
          active: false,
          to: '/technical-support',
        },
        {
          text: 'Time Price',
          active: false,
          to: '/technical-support/time-price',
        },
        {
          text: 'Create Time and Price',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/technical-support/deleted-time-price',
    name: 'technical-support-deleted-time-price',
    component: () => import('@/views/technical-support/time-price/DeletedTimePrice.vue'),
    meta: {
      pageTitle: 'Deleted Time and Price',
      breadcrumb: [
        {
          text: 'Technical Support',
          active: false,
          to: '/technical-support',
        },
        {
          text: 'Time Price',
          active: false,
          to: '/technical-support/time-price',
        },
        {
          text: 'Deleted Time and Price',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/technical-support/update-time-price/:id',
    name: 'technical-support-update-time-price',
    component: () => import('@/views/technical-support/time-price/UpdateTimePrice.vue'),
    meta: {
      pageTitle: 'Update Time Price',
      breadcrumb: [
        {
          text: 'Technical Support',
          active: false,
          to: '/technical-support',
        },
        {
          text: 'Time and Price',
          active: false,
          to: '/technical-support/time-price',
        },
        {
          text: 'Update Time and Price',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
