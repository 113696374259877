import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // Brand
    async ADD_BRAND(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-laptop-brand', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_BRANDS() {
      try {
        const resp = await axios.get('/admin/show-all-laptop-brand')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BRAND(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-laptop-brand/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_BRANDS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-laptop-brand')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BRAND_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-laptop-brand/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_BRAND(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-laptop-brand/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_BRAND(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-laptop-brand/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_BRAND(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-laptop-brand/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Types
    async ADD_TYPE(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-laptop-type', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_TYPES() {
      try {
        const resp = await axios.get('/admin/show-all-laptop-type')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_TYPE(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-laptop-type/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_TYPES() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-laptop-type')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_TYPE_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-laptop-type/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_TYPE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-laptop-type/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_TYPE(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-laptop-type/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_TYPE(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-laptop-type/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Parts
    async ADD_PART(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-laptop-issue', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_PARTS() {
      try {
        const resp = await axios.get('/admin/show-all-laptop-issue')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PART(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-laptop-issue/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_PARTS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-laptop-issue')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PART_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-laptop-issue/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_PART(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-laptop-issue/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_PART(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-laptop-issue/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_PART(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-laptop-issue/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
