import Vue from 'vue'
import VueRouter from 'vue-router'

import macLaptopPcRoutes from './routes/mac-laptop-pc'
import SmartHomeRoutes from './routes/smart-home'
import technicalSupportRoutes from './routes/technical-support'
import businessItSupportRoutes from './routes/business-it-support'
import mobileTabletRoutes from './routes/mobile-tablet'
import discountRoutes from './routes/discounts'
import calendarRoutes from './routes/calendar'
import reportRoutes from './routes/reports'
import orderRoutes from './routes/orders'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/dashboard/index.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    /** ************************************
                    Postcode
    ************************************* */
    {
      path: '/postcode',
      name: 'postcode',
      component: () => import('@/views/postcode/Postcode.vue'),
      meta: {
        pageTitle: 'Postcode',
        breadcrumb: [
          {
            text: 'Postcode',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/postcode/create',
      name: 'postcode-create',
      component: () => import('@/views/postcode/Create.vue'),
      meta: {
        pageTitle: 'Create Postcode',
        breadcrumb: [
          {
            text: 'Postcode',
            active: false,
            to: '/postcode',
          },
          {
            text: 'Create Postcode',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/postcode/update/:id',
      name: 'postcode-update',
      component: () => import('@/views/postcode/Update.vue'),
      meta: {
        pageTitle: 'Update Postcode',
        breadcrumb: [
          {
            text: 'Postcode',
            active: false,
            to: '/postcode',
          },
          {
            text: 'Update Postcode',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/postcode/deleted-postcodes',
      name: 'postcode-deleted-postcodes',
      component: () => import('@/views/postcode/DeletedPostcode.vue'),
      meta: {
        pageTitle: 'Deleted Postcodes',
        breadcrumb: [
          {
            text: 'Postcode',
            active: false,
            to: '/postcode',
          },
          {
            text: 'Deleted Postcodes',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    /** ************************************
                    Postcode Group
    ************************************* */
    {
      path: '/postcode-group',
      name: 'postcode-group',
      component: () => import('@/views/postcode/postcode-group/Index.vue'),
      meta: {
        pageTitle: 'Postcode Group',
        breadcrumb: [
          {
            text: 'Postcode Group',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/postcode-group/create',
      name: 'postcode-group-create',
      component: () => import('@/views/postcode/postcode-group/Create.vue'),
      meta: {
        pageTitle: 'Create Postcode Group',
        breadcrumb: [
          {
            text: 'Postcode Group',
            active: false,
            to: '/postcode-group',
          },
          {
            text: 'Create Postcode Group',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/postcode-group/deleted-postcode-groups',
      name: 'postcode-groups-deleted',
      component: () => import('@/views/postcode/postcode-group/DeletedPostcodeGroup.vue'),
      meta: {
        pageTitle: 'Deleted Postcode Groups',
        breadcrumb: [
          {
            text: 'Postcode Group',
            active: false,
            to: '/postcode-group',
          },
          {
            text: 'Deleted Postcodes',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/postcode-group/update/:id',
      name: 'postcode-group-update',
      component: () => import('@/views/postcode/postcode-group/Update.vue'),
      meta: {
        pageTitle: 'Update Postcode Group',
        breadcrumb: [
          {
            text: 'Postcode Group',
            active: false,
            to: '/postcode-group',
          },
          {
            text: 'Update Postcode Group',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    /** ************************************
                    USER
    ************************************* */
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/user/User.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'user',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/user/create',
      name: 'user-create',
      component: () => import('@/views/user/Create.vue'),
      meta: {
        pageTitle: 'Create User',
        breadcrumb: [
          {
            text: 'Users',
            active: false,
            to: '/user',
          },
          {
            text: 'Create User',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/user/update/:id',
      name: 'user-update',
      component: () => import('@/views/user/Update.vue'),
      meta: {
        pageTitle: 'Update User',
        breadcrumb: [
          {
            text: 'User',
            active: false,
            to: '/user',
          },
          {
            text: 'Edit User',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/user/profile',
      name: 'user-profile',
      component: () => import('@/views/user/Profile.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'User',
            active: false,
            to: '/user',
          },
          {
            text: 'Profile',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/user/update-password',
      name: 'user-update-password',
      component: () => import('@/views/user/UpdatePassword.vue'),
      meta: {
        pageTitle: 'Update Password',
        breadcrumb: [
          {
            text: 'User',
            active: false,
            to: '/user',
          },
          {
            text: 'Update Password',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/user/deleted-users',
      name: 'users-deleted',
      component: () => import('@/views/user/DeletedUsers.vue'),
      meta: {
        pageTitle: 'Deleted Users',
        breadcrumb: [
          {
            text: 'User',
            active: false,
            to: '/user',
          },
          {
            text: 'Deleted Users',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    /** ************************************
                    CATEGORY
    ************************************* */
    {
      path: '/category',
      name: 'category',
      component: () => import('@/views/category/Categories.vue'),
      meta: {
        pageTitle: 'Category',
        breadcrumb: [
          {
            text: 'Category',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/category/create',
      name: 'category-create',
      component: () => import('@/views/category/Create.vue'),
      meta: {
        pageTitle: 'Create Category',
        breadcrumb: [
          {
            text: 'Category',
            active: false,
            to: '/category',
          },
          {
            text: 'Create Category',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/category/update/:id',
      name: 'category-update',
      component: () => import('@/views/category/Update.vue'),
      meta: {
        pageTitle: 'Update Category',
        breadcrumb: [
          {
            text: 'Category',
            active: false,
            to: '/category',
          },
          {
            text: 'Update Category',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/category/deleted-category',
      name: 'category-deleted',
      component: () => import('@/views/category/DeletedCategories.vue'),
      meta: {
        pageTitle: 'Deleted Categories',
        breadcrumb: [
          {
            text: 'Category',
            active: false,
            to: '/category',
          },
          {
            text: 'Deleted Categories',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    ...macLaptopPcRoutes,
    ...SmartHomeRoutes,
    ...technicalSupportRoutes,
    ...businessItSupportRoutes,
    ...mobileTabletRoutes,
    ...discountRoutes,
    ...calendarRoutes,
    ...reportRoutes,
    ...orderRoutes,
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const { requiresAuth } = to.meta
  const token = localStorage.getItem('token')
  if (requiresAuth && !token) {
    next({ name: 'login' })
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
