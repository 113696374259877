import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // Category
    async SHOW_ALL_CATEGORY() {
      try {
        const resp = await axios.get('/admin/show-all-mobile-category')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async ADD_CATEGORY(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-mobile-category', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_CATEGORY(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-mobile-category/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_CATEGORIES() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-mobile-category')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_CATEGORY_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-mobile-category/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_CATEGORY(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-mobile-category/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_CATEGORY(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-mobile-category/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_CATEGORY(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-mobile-category/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Brand
    async ADD_BRAND(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-mobile-brand', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_BRANDS() {
      try {
        const resp = await axios.get('/admin/show-all-mobile-brand')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BRAND(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-mobile-brand/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_BRANDS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-mobile-brand')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_BRAND_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-mobile-brand/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_BRAND(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-mobile-brand/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_BRAND(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-mobile-brand/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_BRAND(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-mobile-brand/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_BRANDS_BY_CATEGORY(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-mobile-brand-by-category/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Models
    async ADD_MODEL(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-mobile-model', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_MODELS() {
      try {
        const resp = await axios.get('/admin/show-all-mobile-model')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_MODEL(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-mobile-model/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_MODELS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-mobile-model')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_MODEL_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-mobile-model/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_MODEL(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-mobile-model/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_MODEL(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-mobile-model/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_MODEL(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-mobile-model/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_MODELS_BY_BRAND(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-mobile-model-by-brand/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Parts
    async ADD_PART(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-mobile-part', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_PARTS() {
      try {
        const resp = await axios.get('/admin/show-all-mobile-part')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PART(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-mobile-part/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_PARTS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-mobile-part')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_PART_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-mobile-part/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_PART(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-mobile-part/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_PART(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-mobile-part/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_PART(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-mobile-part/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Color
    async ADD_COLOR(ctx, data) {
      try {
        const resp = await axios.post('/admin/add-mobile-color', data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_COLORS() {
      try {
        const resp = await axios.get('/admin/show-all-mobile-color')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async SHOW_ALL_COLORS_BY_MODEL_ID(ctx, data) {
      try {
        const resp = await axios.get(`/web/show-mobile-colours-by-model/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_COLOR(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/delete-mobile-color/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_ALL_DELETED_COLORS() {
      try {
        const resp = await axios.get('/admin/show-all-deleted-mobile-color')
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
    async DELETE_COLOR_PERMANENT(ctx, data) {
      try {
        const resp = await axios.delete(`/admin/permanent-delete-mobile-color/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async RESTORE_COLOR(ctx, data) {
      try {
        const resp = await axios.get(`/admin/restore-delete-mobile-color/${data}`)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    async FETCH_SINGLE_COLOR(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-mobile-color/${data}`)
        if (resp.status === 200) {
          return resp.data.data[0]
        }
        return null
      } catch (err) {
        return null
      }
    },
    async UPDATE_COLOR(ctx, data) {
      try {
        const resp = await axios.post(`/admin/update-mobile-color/${data.id}`, data.data)
        if (resp.status === 200) {
          return true
        }
        return null
      } catch (err) {
        return null
      }
    },
    // Pending
    async SHOW_COLORS_BY_MODEL(ctx, data) {
      try {
        const resp = await axios.get(`/admin/show-mobile-color-by-model/${data}`)
        if (resp.status === 200) {
          return resp.data.data
        }
        return null
      } catch (err) {
        return null
      }
    },
  },
}
